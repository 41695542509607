export class FeedDB {
  private db: IDBDatabase | null = null
  private readonly DB_NAME = "submit_feed_cache"
  private readonly STORE_NAME = "keyvaluepairs"
  private readonly DB_VERSION = 1

  private toTimestamp(date: Date | number): number {
    return date instanceof Date ? date.getTime() : date
  }

  async init(): Promise<void> {
    return new Promise((resolve, reject): void => {
      const request = indexedDB.open(this.DB_NAME, this.DB_VERSION)

      request.onerror = () => reject(request.error)
      request.onsuccess = () => {
        this.db = request.result
        resolve()
      }

      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as IDBOpenDBRequest).result
        if (!db.objectStoreNames.contains(this.STORE_NAME)) {
          db.createObjectStore(this.STORE_NAME)
        }
      }
    })
  }

  private getKey(userId: string, context: string, type: 'feed' | 'metadata' | 'seen'): string {
    return `${userId}_${context}_${type}`
  }

  async addFeedItems(userId: string, context: string, items: any[]): Promise<void> {
    if (!this.db) await this.init()
    const key = this.getKey(userId, context, 'feed')

    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction(this.STORE_NAME, 'readwrite')
      const store = transaction.objectStore(this.STORE_NAME)

      const request = store.get(key)
      request.onsuccess = () => {
        const existingItems = request.result || []
        // Create a Set of existing IDs for faster lookup
        const existingIds = new Set(existingItems.map((item: any) => item._id))
        // Filter out items that already exist
        const newItems = items.filter((item: any) => !existingIds.has(item._id))

        const allItems = [...existingItems, ...newItems]
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

        const putRequest = store.put(allItems, key)
        putRequest.onsuccess = () => resolve()
        putRequest.onerror = () => reject(putRequest.error)
      }
      request.onerror = () => reject(request.error)
    })
  }

  async updateFeedItem(userId: string, context: string, itemId: string, updates: Partial<any>): Promise<void> {
    if (!this.db) await this.init()
    const key = this.getKey(userId, context, 'feed')

    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction(this.STORE_NAME, 'readwrite')
      const store = transaction.objectStore(this.STORE_NAME)

      const request = store.get(key)
      request.onsuccess = () => {
        const items = request.result || []
        const itemIndex = items.findIndex((item: any) => item._id === itemId)

        if (itemIndex === -1) {
          resolve() // Item not found, silently resolve
          return
        }

        // Update the item while preserving its original properties
        items[itemIndex] = { ...items[itemIndex], ...updates }

        const putRequest = store.put(items, key)
        putRequest.onsuccess = () => resolve()
        putRequest.onerror = () => reject(putRequest.error)
      }
      request.onerror = () => reject(request.error)
    })
  }

  async removeFeedItem(userId: string, context: string, itemId: string): Promise<void> {
    if (!this.db) await this.init()
    const key = this.getKey(userId, context, 'feed')

    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction(this.STORE_NAME, 'readwrite')
      const store = transaction.objectStore(this.STORE_NAME)

      const request = store.get(key)
      request.onsuccess = () => {
        const items = request.result || []
        const updatedItems = items.filter((item: any) => item._id !== itemId)

        const putRequest = store.put(updatedItems, key)
        putRequest.onsuccess = () => resolve()
        putRequest.onerror = () => reject(putRequest.error)
      }
      request.onerror = () => reject(request.error)
    })
  }

  async getFeedItems(userId: string, context: string, options: {
    before?: number,
    after?: number,
    limit?: number
  } = {}): Promise<any[]> {
    if (!this.db) await this.init()
    const key = this.getKey(userId, context, 'feed')

    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction(this.STORE_NAME, 'readonly')
      const store = transaction.objectStore(this.STORE_NAME)

      const request = store.get(key)
      request.onsuccess = () => {
        let items = request.result || []

        if (options.before || options.after) {
          items = items.filter((item: any) => {
            const timestamp = new Date(item.createdAt).getTime()
            return (!options.before || timestamp < options.before) &&
                   (!options.after || timestamp > options.after)
          })
        }

        if (options.limit) {
          items = items.slice(0, options.limit)
        }

        resolve(items)
      }
      request.onerror = () => reject(request.error)
    })
  }

  async updateFeedMetadata(userId: string, context: string, metadata: any): Promise<void> {
    if (!this.db) await this.init()
    const key = this.getKey(userId, context, 'metadata')

    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction(this.STORE_NAME, 'readwrite')
      const store = transaction.objectStore(this.STORE_NAME)

      const data = {
        ...metadata,
        timestamp: Date.now(),
        lastPulledAt: this.toTimestamp(metadata.lastPulledAt || Date.now()),
        firstPulledAt: this.toTimestamp(metadata.firstPulledAt || Date.now())
      }

      const request = store.put(data, key)
      request.onsuccess = () => resolve()
      request.onerror = () => reject(request.error)
    })
  }

  async getFeedMetadata(userId: string, context: string): Promise<any> {
    if (!this.db) await this.init()
    const key = this.getKey(userId, context, 'metadata')

    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction(this.STORE_NAME, 'readonly')
      const store = transaction.objectStore(this.STORE_NAME)

      const request = store.get(key)
      request.onsuccess = () => resolve(request.result || null)
      request.onerror = () => reject(request.error)
    })
  }

  async markItemAsSeen(userId: string, context: string, itemId: string): Promise<void> {
    if (!this.db) await this.init()
    const key = this.getKey(userId, context, 'seen')

    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction(this.STORE_NAME, 'readwrite')
      const store = transaction.objectStore(this.STORE_NAME)

      const request = store.get(key)
      request.onsuccess = () => {
        const seenItems = new Set(request.result || [])
        seenItems.add(itemId)

        const putRequest = store.put([...seenItems], key)
        putRequest.onsuccess = () => resolve()
        putRequest.onerror = () => reject(putRequest.error)
      }
      request.onerror = () => reject(request.error)
    })
  }

  async getSeenItems(userId: string, context: string): Promise<string[]> {
    if (!this.db) await this.init()
    const key = this.getKey(userId, context, 'seen')

    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction(this.STORE_NAME, 'readonly')
      const store = transaction.objectStore(this.STORE_NAME)

      const request = store.get(key)
      request.onsuccess = () => resolve(request.result || [])
      request.onerror = () => reject(request.error)
    })
  }

  async cleanup(): Promise<void> {
    if (!this.db) await this.init()
    const ONE_DAY = 24 * 60 * 60 * 1000
    const cutoff = Date.now() - ONE_DAY

    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction(this.STORE_NAME, 'readwrite')
      const store = transaction.objectStore(this.STORE_NAME)

      const request = store.getAllKeys()
      request.onsuccess = () => {
        const keys = request.result
        const promises = keys.map(key =>
          new Promise<void>((resolveItem, rejectItem) => {
            const getRequest = store.get(key)
            getRequest.onsuccess = () => {
              const value = getRequest.result
              // Check for lastPulledAt instead of timestamp
              if (value?.lastPulledAt && value.lastPulledAt < cutoff) {
                const deleteRequest = store.delete(key)
                deleteRequest.onsuccess = () => resolveItem()
                deleteRequest.onerror = () => rejectItem(deleteRequest.error)
              } else {
                resolveItem()
              }
            }
            getRequest.onerror = () => rejectItem(getRequest.error)
          })
        )

        Promise.all(promises)
          .then(() => resolve())
          .catch(error => reject(error))
      }
      request.onerror = () => reject(request.error)
    })
  }

  async clearFeedData(userId: string, context: string): Promise<void> {
    if (!this.db) await this.init()

    return new Promise((resolve, reject) => {
      const transaction = this.db!.transaction(this.STORE_NAME, 'readwrite')
      const store = transaction.objectStore(this.STORE_NAME)

      // Clear feed items
      const feedKey = this.getKey(userId, context, 'feed')
      const metadataKey = this.getKey(userId, context, 'metadata')
      const seenKey = this.getKey(userId, context, 'seen')

      Promise.all([
        new Promise((res, rej) => {
          const req = store.delete(feedKey)
          req.onsuccess = () => res(null)
          req.onerror = () => rej(req.error)
        }),
        new Promise((res, rej) => {
          const req = store.delete(metadataKey)
          req.onsuccess = () => res(null)
          req.onerror = () => rej(req.error)
        }),
        new Promise((res, rej) => {
          const req = store.delete(seenKey)
          req.onsuccess = () => res(null)
          req.onerror = () => rej(req.error)
        })
      ])
        .then(() => resolve())
        .catch(error => reject(error))
    })
  }
}

export const feedDB = new FeedDB()
