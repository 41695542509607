import { defineStore } from "pinia"
import API from "../api/api"
import { ref } from "vue"

interface UserModeration {
  createWritings: boolean
  createStatuses: boolean
  createMedia: boolean
  createEvents: boolean
  createGroups: boolean
  createPosts: boolean
  createRelationships: boolean
  createUserInterests: boolean
  createInterestQuestions: boolean
  replyToInterestQuestions: boolean
  moderateWritings: boolean
  moderateStatuses: boolean
  moderateMedia: boolean
  moderateEvents: boolean
  moderateGroups: boolean
  moderatePosts: boolean
  comment: boolean
  react: boolean
  joinGroups: boolean
  followUsers: boolean
  sendMessages: boolean
}

export enum ModerationFlags {
  CREATE_WRITINGS = 1 << 0,
  CREATE_STATUSES = 1 << 1,
  CREATE_MEDIA = 1 << 2,
  CREATE_EVENTS = 1 << 3,
  CREATE_GROUPS = 1 << 4,
  CREATE_POSTS = 1 << 5,
  CREATE_RELATIONSHIPS = 1 << 6,
  CREATE_USER_INTERESTS = 1 << 7,
  CREATE_INTEREST_QUESTIONS = 1 << 8,
  REPLY_TO_INTEREST_QUESTIONS = 1 << 9,
  MODERATE_WRITINGS = 1 << 10,
  MODERATE_STATUSES = 1 << 11,
  MODERATE_MEDIA = 1 << 12,
  MODERATE_EVENTS = 1 << 13,
  MODERATE_GROUPS = 1 << 14,
  MODERATE_POSTS = 1 << 15,
  COMMENT = 1 << 16,
  REACT = 1 << 17,
  JOIN_GROUPS = 1 << 18,
  FOLLOW_USERS = 1 << 19,
  SEND_MESSAGES = 1 << 20,
}

export const DEFAULT_PERMISSIONS =
  ModerationFlags.CREATE_WRITINGS |
  ModerationFlags.CREATE_STATUSES |
  ModerationFlags.CREATE_MEDIA |
  ModerationFlags.CREATE_EVENTS |
  ModerationFlags.CREATE_GROUPS |
  ModerationFlags.CREATE_POSTS |
  ModerationFlags.CREATE_RELATIONSHIPS |
  ModerationFlags.CREATE_USER_INTERESTS |
  ModerationFlags.CREATE_INTEREST_QUESTIONS |
  ModerationFlags.REPLY_TO_INTEREST_QUESTIONS |
  ModerationFlags.COMMENT |
  ModerationFlags.REACT |
  ModerationFlags.JOIN_GROUPS |
  ModerationFlags.FOLLOW_USERS |
  ModerationFlags.SEND_MESSAGES

function decodeModerationFlags(flags: number): UserModeration {
  return {
    createWritings: !!(flags & ModerationFlags.CREATE_WRITINGS),
    createStatuses: !!(flags & ModerationFlags.CREATE_STATUSES),
    createMedia: !!(flags & ModerationFlags.CREATE_MEDIA),
    createEvents: !!(flags & ModerationFlags.CREATE_EVENTS),
    createGroups: !!(flags & ModerationFlags.CREATE_GROUPS),
    createPosts: !!(flags & ModerationFlags.CREATE_POSTS),
    createRelationships: !!(flags & ModerationFlags.CREATE_RELATIONSHIPS),
    createUserInterests: !!(flags & ModerationFlags.CREATE_USER_INTERESTS),
    createInterestQuestions: !!(flags & ModerationFlags.CREATE_INTEREST_QUESTIONS),
    replyToInterestQuestions: !!(flags & ModerationFlags.REPLY_TO_INTEREST_QUESTIONS),
    moderateWritings: !!(flags & ModerationFlags.MODERATE_WRITINGS),
    moderateStatuses: !!(flags & ModerationFlags.MODERATE_STATUSES),
    moderateMedia: !!(flags & ModerationFlags.MODERATE_MEDIA),
    moderateEvents: !!(flags & ModerationFlags.MODERATE_EVENTS),
    moderateGroups: !!(flags & ModerationFlags.MODERATE_GROUPS),
    moderatePosts: !!(flags & ModerationFlags.MODERATE_POSTS),
    comment: !!(flags & ModerationFlags.COMMENT),
    react: !!(flags & ModerationFlags.REACT),
    joinGroups: !!(flags & ModerationFlags.JOIN_GROUPS),
    followUsers: !!(flags & ModerationFlags.FOLLOW_USERS),
    sendMessages: !!(flags & ModerationFlags.SEND_MESSAGES),
  }
}

interface Credentials {
  email: string
  password: string
}

interface SignInOptions {
  rememberMe: boolean
}

interface Location {
  city: any
  state: any
  country: any
  isCityState?: boolean
  place_name_en: string
  geo: {
    type: string
    coordinates: number[]
  }
  display?: string | any[]
  version?: number
}

export const useUserStore = defineStore("user", () => {
  const signedIn = ref<boolean>(false)
  const unverified = ref<boolean>(false)
  const backer = ref<boolean>(false)
  const verified = ref<boolean>(false)
  const firstLetter = ref<string>("")
  const flags = ref<string[]>([])
  const id = ref<string>("")
  const key = ref<string>("")
  const messages = ref<number>(0)
  const notifications = ref<any>({})
  const announcements = ref<number>(0)
  const announcementList = ref<string[]>([])
  const unseenFeaturedAnnouncements = ref<string[]>([])
  const moderation = ref<UserModeration | undefined>(undefined)
  const moderationFlags = ref<number>(0)
  const hasModerationDefaults = ref<boolean>(false)
  const alert = ref<any | undefined>(undefined)
  const pfp = ref<string | undefined>(undefined)
  const location = ref<Location | undefined>(undefined)
  const preferences = ref<any>({})
  const circles = ref<any>({})
  const privacy = ref<any>({})
  const defaultVisibility = ref<string>("public")
  const roles = ref<string[]>([])
  const scopes = ref<string>("")
  const unread = ref<number>(0)
  const username = ref<string>("")

  const selfPostQueue = ref<any[]>([])

  function signIn(credentials: Credentials, options: SignInOptions) {
    return new Promise(async (resolve, reject) => {
      try {
        const payload = {
          email: credentials.email,
          password: credentials.password,
          rememberMe: options.rememberMe || false
        }
        const response = await API(false).post("/authenticate", payload)
        if (response.status === 200) {
          unverified.value = true
          resolve({ status: "unverified", data: response.data })
        }
        if (response.status === 201) {
          signedIn.value = true
          resolve({ status: "signedIn", data: response.data })
        }
        if (response.status === 202) {
          resolve({ status: "totp", data: response.data.data })
        }
      } catch (err: any) {
        if (err.response && err.response.status === 403) {
          return reject({ status: "punished", data: err.response.data.data})
        }
        reject(err)
      }
    })
  }

  function populateUser(data: any) {
    backer.value = data.backer
    verified.value = data.verified
    firstLetter.value = data.firstLetter
    flags.value = data.flags
    id.value = data.id
    key.value = data.key
    messages.value = data.messages
    notifications.value = data.notifications
    announcements.value = data.announcements || 0
    announcementList.value = data.announcementList || []
    unseenFeaturedAnnouncements.value = data.unseenFeaturedAnnouncements || []
    moderation.value = decodeModerationFlags(data.moderation) || undefined
    moderationFlags.value = data.moderation || 0
    hasModerationDefaults.value = data.moderation === DEFAULT_PERMISSIONS
    alert.value = data.alert || undefined
    pfp.value = data.pfp && data.pfp.length > 0 ? data.pfp : undefined
    location.value = data.location ? data.location as Location : undefined
    preferences.value = data.preferences
    circles.value = data.circles || []
    privacy.value = data.privacy
    defaultVisibility.value = data.privacy.defaultVisibility || "public"
    roles.value = data.roles
    scopes.value = data.scopes
    unread.value = data.unread
    username.value = data.username
  }

  function resetUser() {
    backer.value = false
    verified.value = false
    firstLetter.value = ""
    flags.value = []
    id.value = ""
    key.value = ""
    messages.value = 0
    notifications.value = {}
    announcements.value = 0
    announcementList.value = []
    unseenFeaturedAnnouncements.value = []
    moderation.value = undefined
    moderationFlags.value = 0
    alert.value = undefined
    pfp.value = ""
    location.value = undefined
    preferences.value = {}
    privacy.value = {}
    defaultVisibility.value = "public"
    roles.value = []
    scopes.value = ""
    unread.value = 0
    username.value = ""
    selfPostQueue.value = []
  }

  function initUser() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await API(false).get("/init")
        if (response.status === 200) {
          signedIn.value = true
          populateUser(response.data.data)
          resolve(response.data)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  function signOut() {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await API(false).get("/signout")
        if (response.status === 200) {
          const SCROLL_POSITION_KEY = `SBMT_FEED_scrollPosition_${id.value}`
          const FEED_DATA_KEY = `SBMT_FEED_feedData_${id.value}`
          const PAGE_INFO_KEY = `SBMT_FEED_pageInfo_${id.value}`

          sessionStorage.removeItem(SCROLL_POSITION_KEY)
          sessionStorage.removeItem(FEED_DATA_KEY)
          sessionStorage.removeItem(PAGE_INFO_KEY)
          signedIn.value = false
          unverified.value = false
          resetUser()
          resolve(response.data)
        }
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

  function incrementMessages(value: number = 1) {
    if (messages.value >= 0) messages.value += value
  }

  function incrementUnread(value: number = 1) {
    if (unread.value >= 0) unread.value += value
  }

  function setUnread(value: number) {
    unread.value = value
  }

  async function updateUnread() {
    console.log('updated unread fired')
    console.log(`unread: ${unread.value}`)
    if (unread.value > 0) {
      console.log('updating unread')
      try {
        const response = await API().get(`/notifications/markasread`)
        console.log(response)
        setUnread(response.data.data.unread)
      } catch (err) {
        console.error(err)
      }
    }
  }

  function setMessages(value: number) {
    messages.value = value
  }

  function incrementAnnouncements(value: number = 1) {
    if (announcements.value >= 0) announcements.value += value
  }

  function addAnnouncementToList(announcement: string) {
    if (announcementList.value.indexOf(announcement) === -1) {
      announcementList.value.push(announcement)
      incrementAnnouncements(-1)
      if (unseenFeaturedAnnouncements.value.indexOf(announcement) !== -1) {
        unseenFeaturedAnnouncements.value = unseenFeaturedAnnouncements.value.filter((a: string) => a !== announcement)
      }
    }
  }

  function removeAlert() {
    alert.value = undefined
  }

  function addSelfPostToQueue(post: any) {
    selfPostQueue.value.push(post)
  }

  function removeFromSelfPostQueue(index: number) {
    selfPostQueue.value.splice(index, 1)
  }

  function updatePreferences(data: any) {
    const key = Object.keys(data)[0]
    preferences.value[key] = data[key]
  }

  function updateNotifications(data: any) {
    notifications.value = data
  }

  function updateDefaultVisibility(value: string) {
    defaultVisibility.value = value
  }

  function updateFollowingEnabled(value: boolean) {
    privacy.value.followingEnabled = value
  }

  function updateMessagesEnabled(value: boolean) {
    privacy.value.messagesEnabled = value
  }

  function updatePFP(value: string | undefined) {
    pfp.value = value
  }

  function updateLocation(value: Location | undefined) {
    location.value = value
  }

  function addCircle(circle: any) {
    circles.value.push(circle)
  }

  function removeCircle(circle: string) {
    circles.value = circles.value.filter((c: any) => c.code !== circle)
  }

  function updateCircle(circle: string, action: string) {
    switch (action) {
      case "add":
        // increment count on circle
        circles.value.find((c: any) => c.code === circle).count += 1
        break
      case "remove":
        // decrement count on circle
        circles.value.find((c: any) => c.code === circle).count -= 1
        break
    }
  }

  return {
    signedIn,
    unverified,
    backer,
    verified,
    firstLetter,
    flags,
    id,
    key,
    messages,
    notifications,
    announcements,
    announcementList,
    unseenFeaturedAnnouncements,
    moderation,
    moderationFlags,
    hasModerationDefaults,
    alert,
    pfp,
    location,
    preferences,
    circles,
    privacy,
    defaultVisibility,
    roles,
    scopes,
    unread,
    username,
    selfPostQueue,
    signIn,
    initUser,
    signOut,
    incrementMessages,
    incrementUnread,
    setUnread,
    updateUnread,
    setMessages,
    incrementAnnouncements,
    addAnnouncementToList,
    removeAlert,
    addSelfPostToQueue,
    removeFromSelfPostQueue,
    updateNotifications,
    updatePreferences,
    updateDefaultVisibility,
    updateFollowingEnabled,
    updateMessagesEnabled,
    updatePFP,
    updateLocation,
    addCircle,
    removeCircle,
    updateCircle
  }
}, { persist: {
  key: "submit:user"
} })
