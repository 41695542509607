import { defineStore } from "pinia"
import { ref } from "vue"

interface BlockData {
  userId: string
  username: string
}

interface ReportData {
  userId: string
  contentId: string
  contentType: string
  group?: string | undefined
  url?: string | undefined
}

export const usePlatformStore = defineStore("platform", () => {
  const codename = ref("Octopussy")
  const version = ref(APP_VERSION)
  const outOfDate = ref(false)
  const hideOutdated = ref(false)
  const theme = ref<string>(localStorage.getItem("theme") || "dark")
  const debug = ref<boolean>(false)
  const loading = ref<boolean>(false)
  const subLoading = ref<boolean>(false)
  const subSubLoading = ref<boolean>(false)
  const rtn = ref<boolean>(false)
  const lastRTNHeartbeat = ref<Date | null>(null)
  const toasts = ref<Set<any>>(new Set())

  const blockOpen = ref<boolean>(false)
  const reportOpen = ref<boolean>(false)
  const searchOpen = ref<boolean>(false)
  const moderationOpen = ref<boolean>(false)
  const blockData = ref<BlockData | null>(null)
  const reportData = ref<ReportData | null>(null)
  const moderationData = ref<any>(null)
  const searchQuery = ref<string>("")
  const searchData = ref<any>([])
  const reportTesting = ref<boolean>(false)

  // Nav Overrides
  // If using these make sure they are set to null when the component is unmounted
  const categoryOverride = ref<string | null>(null)
  const subSectionOverride = ref<string | null>(null)

  function setOutOfDate(value: boolean) {
    outOfDate.value = value
  }

  function setHideOutdated(value: boolean) {
    hideOutdated.value = value
  }

  async function checkForNewVersion() {
    console.log(`Checking for new version...`)
    const response = await fetch(`/version.json?${Date.now()}`)
    const data = await response.json()
    if (data.version !== version.value) {
      console.log(`New version available: ${data.version}!`)
      setOutOfDate(true)
      return true
    } else {
      setOutOfDate(false)
      return false
    }
  }

  function toggleBlockOpen() {
    blockOpen.value = !blockOpen.value
  }

  function toggleReportOpen() {
    reportOpen.value = !reportOpen.value
    if (!reportOpen.value) clearReportData()
  }

  function toggleModerationOpen() {
    moderationOpen.value = !moderationOpen.value
  }

  function setBlockData(data: BlockData) {
    blockData.value = data
  }

  function setReportData(data: ReportData) {
    reportData.value = data
  }

  function setModerationData(data: any) {
    moderationData.value = data
  }

  function toggleSearchOpen() {
    searchOpen.value = !searchOpen.value
  }

  function setSearchQuery(query: string) {
    searchQuery.value = query
  }

  function setSearchData(data: any) {
    searchData.value = data
  }

  function clearBlockData() {
    blockData.value = null
  }

  function clearReportData() {
    setTimeout(() => {
      reportData.value = null
    }, 500)
  }

  function toggleTheme() {
    if (theme.value === "dark") {
      localStorage.setItem("theme", "light")
      document.documentElement.classList.remove("dark")
      theme.value = "light"
    } else {
      localStorage.setItem("theme", "dark")
      document.documentElement.classList.add("dark")
      theme.value = "dark"
    }
  }

  function setLoading(value: boolean) {
    loading.value = value
  }

  function setSubLoading(value: boolean) {
    subLoading.value = value
  }

  function setSubSubLoading(value: boolean) {
    subSubLoading.value = value
  }

  function setRTN(value: boolean) {
    rtn.value = value
  }

  function setLastRTNHeartbeat() {
    lastRTNHeartbeat.value = new Date()
  }

  function addToast(toast: any) {
    toasts.value.add(toast)
  }

  function removeToast(toast: any) {
    toasts.value.delete(toast)
  }

  function setCategoryOverride(category: string) {
    categoryOverride.value = category
  }

  function setSubSectionOverride(subSection: string) {
    subSectionOverride.value = subSection
  }

  function removeCategoryOverride() {
    categoryOverride.value = null
  }

  function removeSubSectionOverride() {
    subSectionOverride.value = null
  }

  return {
    codename, version, outOfDate, hideOutdated, theme, debug, loading, subLoading,
    subSubLoading, rtn, lastRTNHeartbeat, toasts, blockOpen, reportOpen,
    moderationOpen, blockData, moderationData, searchOpen, searchData,
    searchQuery, reportData, reportTesting, categoryOverride,
    subSectionOverride, checkForNewVersion, setOutOfDate, setHideOutdated, toggleTheme,
    setLoading, setSubLoading, setSubSubLoading, setRTN, setLastRTNHeartbeat,
    addToast, removeToast, toggleBlockOpen, toggleReportOpen,
    toggleModerationOpen, setModerationData, toggleSearchOpen, setSearchData,
    setSearchQuery, setBlockData, setReportData, clearBlockData, clearReportData,
    setCategoryOverride, setSubSectionOverride, removeCategoryOverride,
    removeSubSectionOverride }
})
